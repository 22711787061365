// user.module.js

import { packageService } from '../../helpers/backend/package.service';
import { allergyService } from '../../helpers/backend/allergy.service';
import { appointmentService } from '../../helpers/backend/appointment.service';
import { diseasesService } from '../../helpers/backend/diseases.service';
import { medicineTypeService } from '../../helpers/backend/medicineType.service';
import { medicineService } from '../../helpers/backend/medicine.service';
import { labtestService } from '../../helpers/backend/labtest.service';
import { doctorService } from '../../helpers/backend/doctor.service';
import { commonService } from '../../helpers/backend/common.service';

const listing = [];
const state = {
    listing: listing
};

const actions = {
    //Package APIs
    async getPackageList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return packageService.getPackage(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storePackage({ dispatch, commit }, data) {
        return packageService.storePackage(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deletePackage({ dispatch, commit }, { id }) {
        return packageService.deletePackage(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getPackageByID({ dispatch, commit }, { id }) {
        return packageService.getPackageByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updatePackage({ dispatch, commit }, { data, id }) {
        return packageService.updatePackage(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusPackage({ dispatch, commit }, { is_active, id }) {
        return packageService.updateStatusPackage(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //Allergy APIs
    async getAllergyList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return allergyService.getAllergy(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeAllergy({ dispatch, commit }, data) {
        return allergyService.storeAllergy(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteAllergy({ dispatch, commit }, { id }) {
        return allergyService.deleteAllergy(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getAllergyByID({ dispatch, commit }, { id }) {
        return allergyService.getAllergyByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateAllergy({ dispatch, commit }, { data, id }) {
        return allergyService.updateAllergy(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusAllergy({ dispatch, commit }, { is_active, id }) {
        return allergyService.updateStatusAllergy(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //Appointment APIs
    async getAppointmentList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return appointmentService.getAppointment(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeAppointment({ dispatch, commit }, data) {
        return appointmentService.storeAppointment(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteAppointment({ dispatch, commit }, { id }) {
        return appointmentService.deleteAppointment(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getAppointmentByID({ dispatch, commit }, { id }) {
        return appointmentService.getAppointmentByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateAppointment({ dispatch, commit }, { data, id }) {
        return appointmentService.updateAppointment(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusAppointment({ dispatch, commit }, { is_active, id }) {
        return appointmentService.updateStatusAppointment(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },

    //Diseases APIs
    async getDiseasesList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return diseasesService.getDiseases(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeDiseases({ dispatch, commit }, data) {
        return diseasesService.storeDiseases(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteDiseases({ dispatch, commit }, { id }) {
        return diseasesService.deleteDiseases(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getDiseasesByID({ dispatch, commit }, { id }) {
        return diseasesService.getDiseasesByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateDisease({ dispatch, commit }, { data, id }) {
        return diseasesService.updateDisease(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusDisease({ dispatch, commit }, { is_active, id }) {
        return diseasesService.updateStatusDisease(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //MedicineType APIs
    async getMedicineTypeList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return medicineTypeService.getMedicineType(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeMedicineType({ dispatch, commit }, data) {
        return medicineTypeService.storeMedicineType(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteMedicineType({ dispatch, commit }, { id }) {
        return medicineTypeService.deleteMedicineType(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getMedicineTypeByID({ dispatch, commit }, { id }) {
        return medicineTypeService.getMedicineTypeByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateMedicineType({ dispatch, commit }, { data, id }) {
        return medicineTypeService.updateMedicineType(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusMedicine({ dispatch, commit }, { is_active, id }) {
        return medicineTypeService.updateStatusMedicineType(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //LabTest APIs
    async getLabTestList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return labtestService.getLabTest(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeLabTest({ dispatch, commit }, data) {
        return labtestService.storeLabTest(data)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteLabTest({ dispatch, commit }, { id }) {
        return labtestService.deleteLabTest(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getLabTestByID({ dispatch, commit }, { id }) {
        return labtestService.getLabTestByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateLabTest({ dispatch, commit }, { data, id }) {
        return labtestService.updateLabTest(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusLabTest({ dispatch, commit }, { is_active, id }) {
        return labtestService.updateStatusLabTest(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    // //MedicineType APIs
    async getMedicineList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, medicineTypeID }) {
        return medicineService.getMedicine(search, existingPage, perPage, medicineTypeID)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async storeMedicine({ dispatch, commit }, medicine) {
        return medicineService.storeMedicine(medicine)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteMedicine({ dispatch, commit }, { id }) {
        return medicineService.deleteMedicine(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getMedicineByID({ dispatch, commit }, { id }) {
        return medicineService.getMedicineByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateMedicine({ dispatch, commit }, { data, id }) {
        return medicineService.updateMedicine(data, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusMedicine({ dispatch, commit }, { is_active, id }) {
        return medicineService.updateStatusMedicine(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },

    //Doctor APIs
    async getDoctorList({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return doctorService.getDoctor(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateStatusDoctor({ dispatch, commit }, { is_active, id }) {
        return doctorService.updateStatusDoctor(is_active, id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async deleteDoctor({ dispatch, commit }, { id }) {
        return doctorService.deleteDoctor(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getDoctorByID({ dispatch, commit }, { id }) {
        return doctorService.getDoctorByID(id)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async updateDoctor({ dispatch, commit }, { id, doctor }) {
        return doctorService.updateDoctor(id, doctor)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    //Common API

    async getCountry({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000 }) {
        return commonService.getCountry(search, existingPage, perPage)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getState({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, countryID }) {
        return commonService.getState(search, existingPage, perPage, countryID)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getCity({ dispatch, commit }, { search = "", existingPage = 1, perPage = 10000, stateID }) {
        return commonService.getCity(search, existingPage, perPage, stateID)
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },
    async getDashboard({ dispatch, commit }, { }) {
        return commonService.getDashboard()
            .then(
                result => {
                    return result;
                },
                error => {
                    return error;
                }
            );
    },

};

const mutations = {

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}